<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <navbar />
    </b-navbar>
    <b-container class="mb-3">
      <section id="profile-info">
        <!-- change mode -->
        <main-ujian-pauli v-if="detailUjian.ujian.mode == 'pauli'" />
        <main-ujian v-else />
      </section>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from "bootstrap-vue";

import Navbar from "./components/Navbar.vue";
import MainUjian from "./MainUjian.vue";
import MainUjianPauli from "./MainUjianPauli.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    Navbar,
    MainUjian,
    MainUjianPauli,
  },
  computed: {
    detailUjian() {
      return this.$store.state.ujian.detailUjian;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
