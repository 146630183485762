<template>
  <div class="">
    <div class="mt-2">
      <b-card bg-variant="primary">
        <b-row class="justify-content-beetwen">
          <b-col cols="10">
            <h2 class="text-white mt-2">Soal Nomer {{ currentNo }}</h2>
            <h6 class="text-light mt-1">Literasi Bahasa Indonesia</h6>
          </b-col>
          <b-col>
            <b-card no-body>
              <b-card-body class="ml-2 mt-1">
                <feather-icon icon="ClockIcon" class="mr-50" />
                <span class="font-weight-bold">Sisa Waktu </span>
                <h2 class="text-primary mt-1">{{ displayTimer }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-overlay :show="loading">
      <section>
        <div class="mb-2">
          <div class="row">
            <div class="col-sm-10">
              <b-card no-body>
                <b-card-body>
                  <horizontal-scroll>
                    <span class="" v-for="(item, i) in dataSoal" :key="i">
                      <b-button
                        v-if="item.is_doubt == 1"
                        variant="secondary"
                        class="ml-1 mt-1 mb-1"
                        @click="moveSoal(item, i)"
                        >{{ i + 1 }}
                      </b-button>
                      <b-button
                        v-else-if="item.answer"
                        variant="success"
                        class="ml-1 mt-1 mb-1"
                        @click="moveSoal(item, i)"
                        >{{ i + 1 }}
                      </b-button>
                      <b-button
                        v-else
                        variant="outline-secondary"
                        class="ml-1 mt-1 mb-1"
                        @click="moveSoal(item, i)"
                        >{{ i + 1 }}
                      </b-button>
                    </span>
                  </horizontal-scroll>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-sm">
              <b-card no-body bg-variant="light-secondary">
                <b-card-body class="text-center">
                  <b-button
                    variant="primary"
                    size="lg"
                    class="mt-1 mb-1"
                    @click="akhiriUjian"
                  >
                    <div class="ml-2 mr-2">
                      <feather-icon icon="LogInIcon" class="mr-50" size="20" />
                      Akhiri
                    </div>
                  </b-button>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
        <b-card>
          <div class="" v-if="currentSoal != 'kosong'">
            <div class="" v-if="currentSoal.file != null">
              <b-img
                :src="apiFile + currentSoal.file.file"
                v-if="
                  currentSoal.file.format_file == 'jpg' ||
                  currentSoal.file.format_file == 'png'
                "
                class="img-fluid"
              ></b-img>
            </div>

            <div class="" v-html="currentSoal.content"></div>
            <hr />
            <div class="" v-for="(opsi, i) in currentOption" :key="i">
              <div class="d-flex" v-if="currentSoal.type == 'pg'">
                <b-form-radio
                  v-model="selected"
                  :value="opsi.id"
                  name="flavour-3a"
                ></b-form-radio>
                <!-- <input type="radio" name="option" class="option-input radio" v-model="selected" :value="opsi.id" /> -->
                <b> {{ opsi.option.toUpperCase() }}. </b>
                <div class="" v-if="opsi.file != null">
                  <b-img
                    :src="apiFile + opsi.file.file"
                    v-if="
                      opsi.file.format_file == 'jpg' ||
                      currentSoal.file.format_file == 'png'
                    "
                    class="img-fluid"
                    height="80px"
                  ></b-img>
                </div>
                <span v-html="opsi.content"></span>
              </div>
              <div
                class="d-flex"
                v-else-if="currentSoal.type == 'multiple_choice'"
              >
                <b-form-checkbox
                  v-model="selecteds"
                  :value="opsi.id"
                  name="flavour-3a"
                >
                </b-form-checkbox>
                <b> {{ opsi.option.toUpperCase() }}. </b>
                <div class="" v-if="opsi.file != null">
                  <b-img
                    :src="apiFile + opsi.file.file"
                    v-if="
                      opsi.file.format_file == 'jpg' ||
                      currentSoal.file.format_file == 'png'
                    "
                    class="img-fluid"
                    height="80px"
                  ></b-img>
                </div>
                <span v-html="opsi.content"></span>
              </div>
              <div
                class="d-flex justify-content-between align-items-center"
                v-else-if="currentSoal.type == 'benar_salah'"
              >
                <div class="d-flex align-items-center">
                  <span v-html="opsi.content"></span>
                  <div class="" v-if="opsi.file != null">
                    <b-img
                      :src="apiFile + opsi.file.file"
                      v-if="
                        opsi.file.format_file == 'jpg' ||
                        currentSoal.file.format_file == 'png'
                      "
                      class="img-fluid"
                      height="80px"
                    ></b-img>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="opsi.selected"
                    value="benar"
                    name="flavour-3a"
                    >Benar
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="ml-1"
                    v-model="opsi.selected"
                    value="salah"
                    name="flavour-3a"
                    >Salah
                  </b-form-checkbox>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="" v-else>
            <center class="text-danger">Tidak Ada</center>
          </div>
        </b-card>
        <!-- <div class="cursor-pointer" v-for="(opsi, i) in currentOpsi" :key="i" @click="selectPg(i)">
        <b-card no-body :id="`opsi_${i}_${currentSoal.id}`" :class="opsi.selected ? 'bg-success text-white' : ''">
          <div class="d-flex mt-1">
            <h4 class="ml-1 mr-1" :class="opsi.selected ? 'text-white' : ''">
              <b>{{ opsi.opsi }}. </b>
            </h4>
            <p v-html="opsi.jawab_opsi"></p>
          </div>
        </b-card>
      </div> -->
      </section>
    </b-overlay>
    <div class="d-flex justify-content-between mt-4 mb-2">
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm"
        @click="prev()"
        :disabled="currentNo == 1"
      >
        <feather-icon icon="ArrowLeftIcon" class="mr-50" />Sebelumnya</b-button
      >
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm ml-1 mr-1"
      >
        <b-form-checkbox v-model="is_doubt" name="flavour-3a"
          >Ragu-ragu(?)
        </b-form-checkbox></b-button
      >
      <b-button
        variant="outline-secondary"
        style="font-size: 12px"
        class="btn-sm"
        @click="next()"
        :disabled="currentNo >= dataSoal.length"
        >Selanjutnya <feather-icon icon="ArrowRightIcon" class="ml-50" />
      </b-button>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BImg,
    HorizontalScroll,
  },

  data() {
    return {
      selected: null,
      selecteds: [],
      is_doubt: false,
      displayTimer: null,
      soals: [],
      dataSoal: [],
      currentSoal: null,
      currentOption: [],
      loading: false,
    };
  },

  computed: {
    detailUjian() {
      return this.$store.state.ujian.detailUjian;
    },
    currentUjian() {
      return this.$store.state.ujian.currentUjian;
    },
    currentUserAnswer() {
      return this.$store.state.ujian.currentUserAnswer;
    },
    currentNo() {
      return this.$store.state.ujian.currentNo;
    },
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },

  watch: {
    selected(event) {
      this.selectPg(event);
    },
    selecteds: {
      handler: function (val, oldVal) {
        this.selectPgs(val);
      },
      deep: true,
    },
    "$store.state.ujian.currentSoal"(soal) {
      if (soal) {
        this.currentSoal = soal;
        if (
          this.currentSoal.type == "benar_salah" &&
          this.currentUserAnswer.answer != null
        ) {
          this.currentOption = soal.options;
          this.cekSoalPernyataan();
        } else {
          this.currentOption = soal.options;
        }
      }
    },
  },

  methods: {
    setTimer() {
      const endDateTime = new Date();
      endDateTime.setMinutes(
        endDateTime.getMinutes() + parseInt(this.detailUjian.timer)
      );
      const end = localStorage.endtime
        ? new Date(localStorage.endtime)
        : endDateTime;
      if (!localStorage.endtime) {
        localStorage.endtime = endDateTime;
      }
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let result = [];
      let timer;

      let showRemaining = async () => {
        result = [];
        const now = new Date();
        const distance = end - now;

        if (distance < 0) {
          clearInterval(timer);
          this.kirimSoal();
          this.loading = true;
          let payload = {
            hasil_id: this.currentUjian.id,
          };
          this.$store
            .dispatch("ujian/selesaiUjian", payload)
            .then((res) => {
              this.loading = false;
              this.$store.commit("ujian/SET_CURRENT_SOAL", null);
              this.$store.commit("ujian/SET_CURRENT_UJIAN", null);
              this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", null);
              this.$store.commit("ujian/SET_CURRENT_NO", null);
              this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", res.data);
              this.$router.push({ name: "hasil-ujian" });
            })
            .catch((err) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: err.response.data.message,
                },
              });
            });
          this.displayTimer = null;
          localStorage.removeItem("endtime");
          return;
        }

        const days = Math.floor(distance / _day);
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);
        if (hours > 0) {
          result.push(hours);
        }

        result.push(minutes < 10 ? `0${minutes}` : minutes);
        result.push(seconds < 10 ? `0${seconds}` : seconds);

        this.displayTimer = result.join(":");
      };

      timer = setInterval(showRemaining, 1000);
    },
    selectPg(index) {},
    selectPgs(index) {
      let string = this.selecteds.join(",");
      let result = [];
      this.selecteds.forEach((v) => {
        result.push(`${v}:benar`);
      });
      let pernyataan = result.join(",");
      let array = string.split(",");
    },
    kirimSoal() {
      if (this.currentSoal.type == "pg") {
        let answer = this.selected;
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        // if (this.is_doubt == true) {
        //   var payload = {
        //     hasil_id: this.currentUjian.id,
        //     is_doubt: 1,
        //     soal_id: this.currentSoal.id,
        //     answer: "-",
        //   };
        // } else {
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };
        // }
        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then((res) => {
            this.loading = false;
            this.dataSoal = res.data;
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      } else if (this.currentSoal.type == "multiple_choice") {
        let answer = this.selecteds.join(",");
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };
        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then((res) => {
            this.loading = false;
            this.dataSoal = res.data;
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      } else if (this.currentSoal.type == "benar_salah") {
        // let anwerthis.
        let result = [];
        this.currentOption.forEach((v) => {
          result.push(`${v.id}:${v.selected}`);
        });
        let answer = result.join(",");
        let soals = JSON.parse(JSON.stringify(this.dataSoal));
        let index = soals.findIndex((item) => {
          return item.soal_id == this.currentSoal.id;
        });
        if (index > -1) {
          soals[index].answer = answer;
          this.dataSoal = soals;
        }
        var payload = {
          hasil_id: this.currentUjian.id,
          soal_id: this.currentSoal.id,
          answer: answer,
        };
        this.$store
          .dispatch("ujian/kirimJawabanSoal", [payload])
          .then((res) => {
            this.loading = false;
            this.dataSoal = res.data;
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    moveSoal(soal, index) {
      this.loading = true;
      this.kirimSoal();
      if (soal.soal.length > 0) {
        this.$store.commit("ujian/SET_CURRENT_SOAL", soal.soal[0]);
      } else {
        this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
      }
      this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", soal);
      this.$store.commit("ujian/SET_CURRENT_NO", index + 1);
      this.cekSoal();
    },
    cekSoal() {
      let currentUserAnswer = this.currentUserAnswer;
      let answer = currentUserAnswer.answer;
      if (answer != null) {
        if (currentUserAnswer.soal[0].type == "pg") {
          this.selected = answer;
        } else if (currentUserAnswer.soal[0].type == "multiple_choice") {
          let answerMultiple = answer.split(",");
          this.selecteds = answerMultiple;
        } else {
          (this.selected = null), (this.selecteds = []);
        }
      }
    },
    cekSoalPernyataan() {
      let answer = this.currentUserAnswer.answer;
      let result = [];
      let pernyataan = answer.split(",");
      pernyataan.forEach((v) => {
        let hasil = v.split(`:`);
        let content = this.currentOption.find((res) => {
          return res.id == hasil[0];
        });
        result.push({
          id: hasil[0],
          soal_id: content.soal_id,
          option: content.option,
          content: content.content,
          is_correct: content.is_correct,
          is_true_or_false: content.is_true_or_false,
          created_at: content.created_at,
          updated_at: content.updated_at,
          file: content.file,
          selected: hasil[1],
        });
      });
      this.currentOption = result;
    },
    next() {
      this.loading = true;
      let nextNo = this.currentNo + 1;
      let nextIndex = nextNo - 1;
      const nextSoal = this.dataSoal[nextIndex];
      this.$store.commit("ujian/SET_CURRENT_NO", nextNo);
      this.kirimSoal();
      if (nextSoal.soal.length > 0) {
        this.$store.commit("ujian/SET_CURRENT_SOAL", nextSoal.soal[0]);
      } else {
        this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
      }
      this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", nextSoal);
      this.cekSoal();
    },
    prev() {
      this.loading = true;
      let prevNo = this.currentNo - 1;
      let prevIndex = prevNo - 1;
      const prevSoal = this.dataSoal[prevIndex];
      this.$store.commit("ujian/SET_CURRENT_NO", prevNo);
      this.kirimSoal();
      if (prevSoal.soal.length > 0) {
        this.$store.commit("ujian/SET_CURRENT_SOAL", prevSoal.soal[0]);
      } else {
        this.$store.commit("ujian/SET_CURRENT_SOAL", "kosong");
      }
      this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", prevSoal);
      this.cekSoal();
    },
    akhiriUjian() {
      this.kirimSoal();
      this.$swal({
        title: "Anda Yakin Mengakhiri Ujian Ini?",
        text: "Jawaban akan otomatis terkirim!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Akhiri",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let payload = {
            hasil_id: this.currentUjian.id,
          };
          this.$store
            .dispatch("ujian/selesaiUjian", payload)
            .then((res) => {
              this.loading = false;
              localStorage.removeItem("endtime");
              this.$store.commit("ujian/SET_CURRENT_SOAL", null);
              this.$store.commit("ujian/SET_CURRENT_UJIAN", null);
              this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", null);
              this.$store.commit("ujian/SET_CURRENT_NO", null);
              this.$store.commit("ujian/SET_CURRENT_HASIL_UJIAN", res.data);
              this.$router.push({ name: "hasil-ujian" });
            })
            .catch((err) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: err.response.data.message,
                },
              });
            });
          this.$swal({
            icon: "success",
            title: "Anda berhasil mengakhiri ujian ini!",
            text: "Semoga hasilnya memuaskan :)",
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal Mengakhiri",
            text: "Silahkan isi jawaban dengan yakin :)",
            icon: "error",
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
  },
  created() {
    this.dataSoal = this.currentUjian.user_answer;
    if (this.dataSoal[0] == null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Error `,
          icon: "AlertIcon",
          variant: "warning",
          text: "Tidak ada soal di ujian ini!",
        },
      });
      this.$router.push({ name: "tryout-detail-subtest" });
    }
    const firstSoal = this.dataSoal[0].soal[0];
    this.$store.commit("ujian/SET_CURRENT_SOAL", firstSoal);
    this.$store.commit("ujian/SET_CURRENT_USER_ANSWER", this.dataSoal[0]);
    this.$store.commit("ujian/SET_CURRENT_NO", 1);
    this.setTimer();
    this.cekSoal();
  },
};
</script>

<style></style>
