var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "mt-2"
  }, [_c('b-card', {
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-beetwen"
  }, [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('h2', {
    staticClass: "text-white mt-2"
  }, [_vm._v("Soal Nomer " + _vm._s(_vm.currentNo))]), _c('h6', {
    staticClass: "text-light mt-1"
  }, [_vm._v("Literasi Bahasa Indonesia")])]), _c('b-col', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "ml-2 mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Sisa Waktu ")]), _c('h2', {
    staticClass: "text-primary mt-1"
  }, [_vm._v(_vm._s(_vm.displayTimer))])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-10"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('horizontal-scroll', _vm._l(_vm.dataSoal, function (item, i) {
    return _c('span', {
      key: i
    }, [item.is_doubt == 1 ? _c('b-button', {
      staticClass: "ml-1 mt-1 mb-1",
      attrs: {
        "variant": "secondary"
      },
      on: {
        "click": function click($event) {
          return _vm.moveSoal(item, i);
        }
      }
    }, [_vm._v(_vm._s(i + 1) + " ")]) : item.answer ? _c('b-button', {
      staticClass: "ml-1 mt-1 mb-1",
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.moveSoal(item, i);
        }
      }
    }, [_vm._v(_vm._s(i + 1) + " ")]) : _c('b-button', {
      staticClass: "ml-1 mt-1 mb-1",
      attrs: {
        "variant": "outline-secondary"
      },
      on: {
        "click": function click($event) {
          return _vm.moveSoal(item, i);
        }
      }
    }, [_vm._v(_vm._s(i + 1) + " ")])], 1);
  }), 0)], 1)], 1)], 1), _c('div', {
    staticClass: "col-sm"
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "bg-variant": "light-secondary"
    }
  }, [_c('b-card-body', {
    staticClass: "text-center"
  }, [_c('b-button', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "variant": "primary",
      "size": "lg"
    },
    on: {
      "click": _vm.akhiriUjian
    }
  }, [_c('div', {
    staticClass: "ml-2 mr-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "LogInIcon",
      "size": "20"
    }
  }), _vm._v(" Akhiri ")], 1)])], 1)], 1)], 1)])]), _c('b-card', [_vm.currentSoal != 'kosong' ? _c('div', {}, [_vm.currentSoal.file != null ? _c('div', {}, [_vm.currentSoal.file.format_file == 'jpg' || _vm.currentSoal.file.format_file == 'png' ? _c('b-img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.apiFile + _vm.currentSoal.file.file
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.currentSoal.content)
    }
  }), _c('hr'), _vm._l(_vm.currentOption, function (opsi, i) {
    return _c('div', {
      key: i
    }, [_vm.currentSoal.type == 'pg' ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-radio', {
      attrs: {
        "value": opsi.id,
        "name": "flavour-3a"
      },
      model: {
        value: _vm.selected,
        callback: function callback($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }), _c('b', [_vm._v(" " + _vm._s(opsi.option.toUpperCase()) + ". ")]), opsi.file != null ? _c('div', {}, [opsi.file.format_file == 'jpg' || _vm.currentSoal.file.format_file == 'png' ? _c('b-img', {
      staticClass: "img-fluid",
      attrs: {
        "src": _vm.apiFile + opsi.file.file,
        "height": "80px"
      }
    }) : _vm._e()], 1) : _vm._e(), _c('span', {
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    })], 1) : _vm.currentSoal.type == 'multiple_choice' ? _c('div', {
      staticClass: "d-flex"
    }, [_c('b-form-checkbox', {
      attrs: {
        "value": opsi.id,
        "name": "flavour-3a"
      },
      model: {
        value: _vm.selecteds,
        callback: function callback($$v) {
          _vm.selecteds = $$v;
        },
        expression: "selecteds"
      }
    }), _c('b', [_vm._v(" " + _vm._s(opsi.option.toUpperCase()) + ". ")]), opsi.file != null ? _c('div', {}, [opsi.file.format_file == 'jpg' || _vm.currentSoal.file.format_file == 'png' ? _c('b-img', {
      staticClass: "img-fluid",
      attrs: {
        "src": _vm.apiFile + opsi.file.file,
        "height": "80px"
      }
    }) : _vm._e()], 1) : _vm._e(), _c('span', {
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    })], 1) : _vm.currentSoal.type == 'benar_salah' ? _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(opsi.content)
      }
    }), opsi.file != null ? _c('div', {}, [opsi.file.format_file == 'jpg' || _vm.currentSoal.file.format_file == 'png' ? _c('b-img', {
      staticClass: "img-fluid",
      attrs: {
        "src": _vm.apiFile + opsi.file.file,
        "height": "80px"
      }
    }) : _vm._e()], 1) : _vm._e()]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "value": "benar",
        "name": "flavour-3a"
      },
      model: {
        value: opsi.selected,
        callback: function callback($$v) {
          _vm.$set(opsi, "selected", $$v);
        },
        expression: "opsi.selected"
      }
    }, [_vm._v("Benar ")]), _c('b-form-checkbox', {
      staticClass: "ml-1",
      attrs: {
        "value": "salah",
        "name": "flavour-3a"
      },
      model: {
        value: opsi.selected,
        callback: function callback($$v) {
          _vm.$set(opsi, "selected", $$v);
        },
        expression: "opsi.selected"
      }
    }, [_vm._v("Salah ")])], 1)]) : _vm._e(), _c('hr')]);
  })], 2) : _c('div', {}, [_c('center', {
    staticClass: "text-danger"
  }, [_vm._v("Tidak Ada")])], 1)])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between mt-4 mb-2"
  }, [_c('b-button', {
    staticClass: "btn-sm",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary",
      "disabled": _vm.currentNo == 1
    },
    on: {
      "click": function click($event) {
        return _vm.prev();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _vm._v("Sebelumnya")], 1), _c('b-button', {
    staticClass: "btn-sm ml-1 mr-1",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "flavour-3a"
    },
    model: {
      value: _vm.is_doubt,
      callback: function callback($$v) {
        _vm.is_doubt = $$v;
      },
      expression: "is_doubt"
    }
  }, [_vm._v("Ragu-ragu(?) ")])], 1), _c('b-button', {
    staticClass: "btn-sm",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "variant": "outline-secondary",
      "disabled": _vm.currentNo >= _vm.dataSoal.length
    },
    on: {
      "click": function click($event) {
        return _vm.next();
      }
    }
  }, [_vm._v("Selanjutnya "), _c('feather-icon', {
    staticClass: "ml-50",
    attrs: {
      "icon": "ArrowRightIcon"
    }
  })], 1)], 1), _c('br'), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }