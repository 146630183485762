var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('navbar')], 1), _c('b-container', {
    staticClass: "mb-3"
  }, [_c('section', {
    attrs: {
      "id": "profile-info"
    }
  }, [_vm.detailUjian.ujian.mode == 'pauli' ? _c('main-ujian-pauli') : _c('main-ujian')], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }