var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "mt-2 text-center",
    staticStyle: {
      "border-left": "4px solid #1442dc"
    }
  }, [_c('h2', [_c('b', [_vm._v(" Kolom " + _vm._s(_vm.currentNoKolom))])])]), _c('b-card', {
    staticClass: "mt-2 text-center"
  }, [_c('h4', [_vm._v("Kolom Ujian")]), _c('hr'), _c('vue-custom-scrollbar', {
    staticClass: "scroll-area",
    attrs: {
      "settings": _vm.settings
    }
  }, _vm._l(_vm.dataKolom, function (item, i) {
    return _c('span', {
      key: i
    }, [_vm.currentKolom.soal_id == item.soal_id ? _c('b-button', {
      staticClass: "m-1",
      staticStyle: {
        "cursor": "default"
      },
      attrs: {
        "variant": "primary",
        "size": "lg"
      }
    }, [_vm._v(_vm._s(i + 1))]) : i < _vm.currentNoKolom ? _c('b-button', {
      staticClass: "m-1",
      staticStyle: {
        "cursor": "default"
      },
      attrs: {
        "variant": "outline-primary",
        "size": "md"
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "CheckIcon"
      }
    })], 1) : _c('b-button', {
      staticClass: "m-1",
      staticStyle: {
        "cursor": "default"
      },
      attrs: {
        "variant": "outline-primary",
        "size": "md"
      }
    }, [_vm._v(_vm._s(i + 1))])], 1);
  }), 0)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('b-card', {
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-beetwen"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h2', {
    staticClass: "text-white mt-2"
  }, [_vm._v(" Soal Dijawab "), _c('b-button', {
    attrs: {
      "variant": "light"
    }
  }, [_vm._v(_vm._s(_vm.currentNo) + "/" + _vm._s(_vm.currentSoals.length))])], 1), _c('h6', {
    staticClass: "text-light mt-1"
  }, [_vm._v(_vm._s(_vm.currentKolom.name))])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "ml-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Sisa Waktu ")]), _c('h2', {
    staticClass: "text-primary mt-1"
  }, [_vm._v(_vm._s(_vm.displayTimer))])], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('h1', {
    staticClass: "mt-1 mb-1 text-white"
  }, [_vm._v(" " + _vm._s(_vm.angka1(_vm.currentSoal.content)) + " ")])]), _c('span', {
    staticClass: "ml-3 mr-3 h1"
  }, [_vm._v(_vm._s(_vm.operator(_vm.currentSoal.content)))]), _c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('h1', {
    staticClass: "mt-1 mb-1 text-white"
  }, [_vm._v(" " + _vm._s(_vm.angka2(_vm.currentSoal.content)) + " ")])])], 1), _c('b-card', [_c('center', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "lg",
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.isiGenap
    }
  }, [_vm._v("0")]), _c('b-button', {
    attrs: {
      "size": "lg",
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.isiGanjil
    }
  }, [_vm._v("1")])], 1)], 1)], 1)])], 1), _c('br'), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }